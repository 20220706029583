const staticLibs = {
  $ : require("jquery"),
  SVG : require("./svg.js"),
  DragDropTouch : require("./DragDropTouch.js"),
}

const commonjsGlobal = globalThis || window || global || self || {};

Object.assign(commonjsGlobal, staticLibs);

export default staticLibs;
